import React from 'react'
import PropTypes from 'prop-types'

/**
 * Loop throught param and return JSX elements 
 *
 * @param {array} organizationRepeater 
 *
 * @returns {JSX Element}
 */
export default function StartOrgRepeater({ organizationRepeater }) {
  return (
    <div className="start-org__repeater">
      {organizationRepeater && organizationRepeater.map((item, index) => {

        return (
          <div className="start-org__repeater-item" key={index}>
            <div className="start-org__repeater-item-index">
              {index + 1}
            </div>
            <div className="start-org__repeater-item-content">
              <h3>{item.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

StartOrgRepeater.propTypes = {
  organizationRepeater: PropTypes.arrayOf(PropTypes.object)
}