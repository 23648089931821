import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

/**
 * StartOrgHeader component
 *
 * @param {object} organizationHeader 
 *
 * @returns {JSX Element}
 */
export default function StartOrgHeader({ organizationHeader }) {

  const { image, title, description } = organizationHeader

  return (
    <section className="start-org__header">
      {image && <Img fluid={image.file.startOrgHeaderImage?.fluid} alt="start an organisation" className="start-org__header-image" />}
      <div className="start-org__header-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </section>
  )
}

StartOrgHeader.propTypes = {
  organizationHeader: PropTypes.object
}